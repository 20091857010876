import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Freemeditationbreadcreumb from "../components/connect/freemeditation/free-meidtation-breadcumb";

import Freemeditationbanner from "../components/connect/freemeditation/meditate-banner";

import Personaltrainersec from "../components/connect/freemeditation/meditate-trainer";

import FreemeditationSlider from "../components/connect/freemeditation/free-meditation-slider";

// markup
const FreemeditationPage = () => {
  return (
    <div>
      <Seo title="Free Meditation with a Trainer" />

      <Layout isSticky>
        <section>
          <Freemeditationbreadcreumb />
        </section>
        <section>
          <Freemeditationbanner />
        </section>

        <section>
          <Personaltrainersec />

        </section>
        <section>
          <FreemeditationSlider/>
        </section>
      </Layout>
    </div>
  );
};

export default FreemeditationPage;
