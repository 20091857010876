import React from "react";

import onetoone from "../../../assets/images/connect/one-to-one-connect_1900-x500.jpg";

export default function Freemeditationbanner() {
  return (
    <div className="free-meditation-banner-wrapper">
      <img className="img-fluid" src={onetoone} alt="youtube" />
    </div>
  );
}
