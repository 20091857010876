import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import arrowleft from "../../../assets/images/testimonial-slider/Icon ionic-ios-arrow-dropleft.png";


import arrowright from "../../../assets/images/testimonial-slider/Icon ionic-ios-arrow-dropright.png";

export default function Testimonailslider() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            <img src={arrowleft}  />
          </button>
          <button onClick={() => next()}>
            <img src={arrowright} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid bg-freemeditation">
    <div className="bg-freemeditation testimonial-slider container pad60">
      <div className="row">
        

        <div className="carousel-section">
          <Carousel
            arrows={true}
            showDots={false}
            autoPlaySpeed={3000}
            draggable
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={false}
            swipeable
            responsive={responsive}
           
          
            // customButtonGroup={<ButtonGroup />}
          >
              <div className="col-md-12 mar0 pad99">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
               
                  <p class="testimonialcard-text-connect freemeditation-text mt-2"> Harshit, Vancouver, Canada</p>
                  <h4 className=" theme-color testimonal-name freemeditation-heading">
                    {" "}
                    Software Engineer{" "}
                  </h4>
                  <hr/>
                  <p class="testimonialcard-text freemeditation-ptag mb-5">
                    {" "}
                    Heartfulness Meditation is not only a good experience but altogether a new life. In today’s world, a balance is highly required between your professional & personal life, this meditation serves as food for your soul.
                  <br/> <br/> <br/>
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-12 mar0 pad99">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
               
                <p class="testimonialcard-text-connect freemeditation-text mt-2"> Mamta Patel, Ahmedabad</p>
                  <h4 className=" theme-color testimonal-name freemeditation-heading">
                    {" "}
                    House Wife{" "}
                  </h4>
                  <hr/>
                  <p class="testimonialcard-text freemeditation-ptag mb-5">
                    {" "}
                    I am housewife and day to day housework is tiresome. Before Starting meditation my mind used to be confused and was Indulged I negative thoughts but after starting meditation I have Learnt to face situations with calm mind. Body and mind feel Relaxed and peaceful. These changes were not sudden all Happened gradually but day by day I will more positive and it’s Very soothing.
                  
                  </p>
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0 pad99">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
               
                  <p class="testimonialcard-text-connect freemeditation-text mt-2"> Harshit, Vancouver, Canada</p>
                  <h4 className=" theme-color testimonal-name freemeditation-heading">
                    {" "}
                    Software Engineer{" "}
                  </h4>
                  <hr/>
                  <p class="testimonialcard-text freemeditation-ptag mb-5">
                    {" "}
                    Heartfulness Meditation is not only a good experience but altogether a new life. In today’s world, a balance is highly required between your professional & personal life, this meditation serves as food for your soul.
                  <br/> <br/> <br/>
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-12 mar0 pad99">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
               
                <p class="testimonialcard-text-connect freemeditation-text mt-2"> Mamta Patel, Ahmedabad</p>
                  <h4 className=" theme-color testimonal-name freemeditation-heading">
                    {" "}
                    House Wife{" "}
                  </h4>
                  <hr/>
                  <p class="testimonialcard-text freemeditation-ptag mb-5">
                    {" "}
                    I am housewife and day to day housework is tiresome. Before Starting meditation my mind used to be confused and was Indulged I negative thoughts but after starting meditation I have Learnt to face situations with calm mind. Body and mind feel Relaxed and peaceful. These changes were not sudden all Happened gradually but day by day I will more positive and it’s Very soothing.
                  
                  </p>
                  
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
    </div>
  );
}
