import React from "react";

import Trainer from "../../../assets/images/learn/Personel-trainer-learn.png";

import { Link } from "gatsby";

export default function Personaltrainersec() {
  return (
    <section className="personal-trainer-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4 className="theme-h4 meditate-trainer-head mg-bottom20">
              {" "}
              WHY MEDITATE WITH A{" "}
              <span className="theme-color"> PERSONAL TRAINER? </span>
            </h4>
            <p className=" shareidea-p-tag">
              {" "}
              We all need moments of peace and joy in life. Rather than waiting for them to happen by chance, why not take the lead? Learn to dive deep in meditation and surface feeling refreshed, rejuvenated and resilient. Learn to master your emotions, listen to your heart, and find inner stillness and joy. You will soon find that you are designing your own destiny.
            </p>
            <p className="shareidea-p-tag">
              {" "}
              Our certified Heartfulness trainers will guide you and mentor you through the practices, answer your questions, and provide ongoing support. These free guided meditation sessions are currently being conducted only online, one to one.{" "}
            </p>
            <p className="shareidea-p-tag">
              {" "}
              <span className="link-color-eligibility"> Eligibility Criteria: </span> Age 16
              &amp; above.{" "}
            </p>
            <p>
              {" "}
              If you have any questions, please write to us at{" "}<br/>
              <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
            </p>
          </div>
          <div class="col-md-6 padd-0">
            <div class="cat-img-col">
              <Link
                to="https://youtu.be/-bGeWAKKckc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid mx-5" src={Trainer} alt="Relax being" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
